@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  body {
    margin: 0;
    @apply text-slate-900;
    font-family: "Montserrat", sans-serif;
    overflow-x: hidden;
  }
  .title,
  .textbx {
    font-family: "Abril Fatface", cursive !important;
  }

  #menu-toggle:checked + #menu {
    display: block;
  }

  .navicon {
    width: 1.125em;
    height: 0.125em;
  }

  .navicon::before,
  .navicon::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-out;
    content: "";
    background: #ffff;
  }

  .navicon::before {
    top: 5px;
  }

  .navicon::after {
    top: -5px;
  }

  .menu-btn:not(:checked) ~ .menu {
    display: none;
  }

  .menu-btn:checked ~ .menu {
    display: block;
  }

  .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }

  .menu-btn:checked ~ .menu-icon .navicon::before {
    transform: rotate(-45deg);
  }

  .menu-btn:checked ~ .menu-icon .navicon::after {
    transform: rotate(45deg);
  }

  .menu-btn:checked ~ .menu-icon .navicon::before,
  .menu-btn:checked ~ .menu-icon .navicon::after {
    top: 0;
  }

  .heroimg:before {
    @apply hidden lg:block absolute bg-slate-200 h-[760px] -top-20 xl:-top-36;
    content: "";
    width: 45%;
    right: 15%;
  }
  .heroimg:after {
    @apply hidden lg:block absolute bg-yellow-500 left-0 z-[1] h-[300px] top-1/3 xl:top-1/4;
    content: "";
    width: 45%;
    z-index: -1;
  }

  .hero-im:after {
    content: "";
    height: 70%;
    bottom: 8%;
    z-index: -1;
    @apply hidden lg:block border-4 border-slate-900 w-1/2 -right-12 absolute;
  }

  .hero-im:before {
    @apply hidden lg:block border-4 border-slate-200 absolute -top-20 xl:-top-36 h-36 bg-transparent;
    content: "";
    width: 45%;
    left: 20%;
    z-index: -1;
  }

  .heroimg {
    filter: brightness(0.8);
  }

  @media only screen and (max-width: 600px) {
    .heroimg:after {
      height: 160px;
    }
    .hero-rig {
      padding: 20px 0px;
    }
    .hero-im:after {
      right: -25px;
    }
  }

  .hotel-cover1 {
    background-image: url(./assets/img/room1.jpg);
  }
  .hotel-cover2 {
    background-image: url(./assets/img/room2.jpg);
  }
  .hotel-cover3 {
    background-image: url(./assets/img/room3.jpg);
  }

  .bg-gradient-black-transparent {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  @media screen and (min-width: 640px) {
    .bg-gradient-black-transparent {
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 0.1) 100%
      );
    }
  }

  .a-button {
    background-color: #ffffff;
    border-radius: 100%;
    cursor: pointer;
    position: relative;
    transition: box-shadow 0.3s cubic-bezier(0.5, 0.61, 0.355, 1),
      opacity 0.3s cubic-bezier(0.5, 0.61, 0.355, 1);
  }

  .a-button:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
    outline: none;
  }

  .a-button:hover {
    opacity: 0.75;
  }

  .a-button__icon {
    padding: 0.625rem;
  }

  .a-icon {
    fill: #000000;
    height: 2.1875rem;
    vertical-align: top;
    width: 2.1875rem;
  }

  @media screen and (min-width: 32em) {
    .a-icon {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  .m-video__button {
    bottom: 50%;
    left: 50%;
    position: absolute;
    will-change: opacity;
    z-index: 20;
  }
  .m-fullscreen__button {
    top: 0.9375rem;
    right: 0.9375rem;
    position: absolute;
    will-change: opacity;
    z-index: 20;
  }

  .no-enhance .m-video__button {
    display: none;
  }

  .m-video__button[aria-pressed="true"] {
    display: none;
  }

  .controls {
    width: 85vw;
    max-width: 600px;
    background-color: #52134b;
    padding: 5px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px rgb(134, 134, 134);
    border-top: 0;

    margin-bottom: 20px;
  }

  .controls .btn-controls {
    height: 25px;
    width: 25px;
    background-color: transparent;
    border: 0;
    color: white;
    cursor: pointer;
    transition: 0.4s;
  }
  .controls .btn-controls:hover {
    color: #fccf23;
    transition: 0.4s;
  }

  .controls .btn-controls:focus {
    outline: 0;
  }

  .controls span {
    color: white;
    margin-left: 6px;
  }

  /*progress bar*/
  input[type="range"] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type="range"]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type="range"]::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  /* Special styling for WebKit/Blink */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #fccf23;
    cursor: pointer;
    margin-top: -4px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    box-shadow: 1px 2px 10px #000000;
  }

  /* All the same stuff for Firefox */
  input[type="range"]::-moz-range-thumb {
    box-shadow: 1px 2px 10px #000000;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #fccf23;
    cursor: pointer;
  }

  /* All the same stuff for IE */
  input[type="range"]::-ms-thumb {
    box-shadow: 1px 2px 10px #000000;
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: #fccf23;
    cursor: pointer;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: white;
    border-radius: 4px;
    border: 0;
    margin-left: 8px;
  }

  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #faf17b;
  }

  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: white;
    border-radius: 4px;
    border: 0;
    margin-left: 8px;
  }

  input[type="range"]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: #2a6495;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: #3071a9;
  }
  input[type="range"]::-ms-fill-upper {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #367ebd;
  }
}
