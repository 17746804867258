@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }
  80% {
    transform: translateX(1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }
  80% {
    transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.heading-secondary {
  @apply bg-gradient-to-r from-yellow-400 to-yellow-600;
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 0.2rem;
  transition: all 0.2s;
}

.heading-secondary:hover {
  transform: skewY(2deg) skewX(15deg) scale(1.1);
  text-shadow: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.2);
}

.u-center-text {
  text-align: center !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}

.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
}
@media only screen and (max-width: 56.25em) {
  .u-margin-bottom-medium {
    margin-bottom: 3rem !important;
  }
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}
@media only screen and (max-width: 56.25em) {
  .u-margin-bottom-big {
    margin-bottom: 5rem !important;
  }
}

.u-margin-top-big {
  margin-top: 8rem !important;
}

.u-margin-top-huge {
  margin-top: 10rem !important;
}

.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0.15;
  overflow: hidden;
}
.bg-video__content {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.btn,
.btn:link,
.btn:visited {
  @apply relative text-sm sm:text-base border-none cursor-pointer no-underline uppercase rounded-[10rem] transition-all py-2 px-6 sm:py-3 sm:px-8 lg:py-6 lg:px-16;
}

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}
.btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.btn:active,
.btn:focus {
  outline: none;
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.btn--white {
  background-color: #fff;
  color: #777;
}
.btn--white::after {
  background-color: #fff;
}

.btn--yellow {
  background-color: #eab308;
  color: #fff;
}
.btn--yellow::after {
  background-color: #eab308;
}

.btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.btn--animated {
  animation: moveInBottom 0.5s ease-out 0.75s;
  animation-fill-mode: backwards;
}

.btn-text:link,
.btn-text:visited {
  font-size: 1rem;
  @apply text-yellow-600;
  display: inline-block;
  text-decoration: none;
  @apply border-b border-yellow-700;
  padding: 3px;
  transition: all 0.2s;
}

.btn-text:hover {
  background-color: #eab308;
  color: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.btn-text:active {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transform: translateY(0);
}

.composition__photo {
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.4);
  border-radius: 2px;
  position: absolute;
  z-index: 10;
  transition: all 0.2s;
  outline-offset: 1rem;
}
.composition__photo--p1 {
  left: 0;
  top: -1rem;
}
.composition__photo--p2 {
  right: 0;
  top: 2rem;
}
.composition__photo--p3 {
  left: 20%;
  top: 10rem;
}
.composition__photo:hover {
  outline: 1rem solid #eab308;
  transform: scale(1.05) translateY(-0.5rem);
  box-shadow: 0 2.5rem 4rem rgba(0, 0, 0, 0.5);
  z-index: 20;
}
.composition:hover .composition__photo:not(:hover) {
  transform: scale(0.95);
}

.feature-box {
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 1.5rem;
  padding: 2.5rem;
  text-align: center;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
}
@media only screen and (max-width: 56.25em) {
  .feature-box {
    padding: 2rem;
  }
}
.feature-box__icon {
  font-size: 6rem;
  margin-bottom: 0.5rem;
  display: inline-block;
  background-image: linear-gradient(to right, #2998ff, #5643fa);
  -webkit-background-clip: text;
  color: transparent;
}
@media only screen and (max-width: 56.25em) {
  .feature-box__icon {
    margin-bottom: 0;
  }
}
.feature-box:hover {
  transform: translateY(-1.5rem) scale(1.03);
}

.form__group:not(:last-child) {
 @apply mb-1 sm:mb-4 lg:mb-5;
}

.form__input {
  @apply text-sm md:text-base py-2 px-3 md:py-4 md:px-6;
  font-family: inherit;
  color: inherit;
  border-radius: 2px;
  background-color: rbga(#fff, 0.5);
  border: none;
  border-bottom: 3px solid transparent;
  width: 90%;
  display: block;
  transition: all 0.3s;
}
@media only screen and (max-width: 56.25em) {
  .form__input {
    width: 100%;
  }
}
.form__input:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  border-bottom: 3px solid #55c57a;
}
.form__input:focus:invalid {
  border-bottom: 3px solid #ff7730;
}
.form__input::-webkit-input-placeholder {
  color: #999;
}

.form__label {
  @apply text-sm md:text-base font-semibold block py-2 md:py-4;
  transition: all 0.3s;
}

.form__radio-group {
  width: 49%;
  display: inline-block;
}
@media only screen and (max-width: 56.25em) {
  .form__radio-group {
    width: 100%;
    margin-bottom: 2rem;
  }
}

.form__radio-input {
  display: none;
}

.form__radio-label {
  font-size: 1rem;
  cursor: pointer;
  position: relative;
  padding-left: 4.5rem;
}

.form__radio-button {
  height: 3rem;
  width: 3rem;
  border: 5px solid #eab308;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: -0.4rem;
}
.form__radio-button::after {
  content: "";
  display: block;
  height: 1.3rem;
  width: 1.3rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #eab308;
  opacity: 0;
  transition: opacity 0.2s;
}

.form__radio-input:checked ~ .form__radio-label .form__radio-button::after {
  opacity: 1;
}

.header {
  height: 85vh;
  background-image: url(../assets/img/landing.jpg);
  background-size: cover;
  background-position: center;
  position: relative;
}

@media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
  only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
  only screen and (min-width: 125em) {
  .header {
    background-image: linear-gradient(
        to right bottom,
        rgba(126, 213, 111, 0.8),
        rgba(40, 180, 133, 0.8)
      ),
      url(https://i.imgur.com/uRdhN2N.jpg);
  }
}
@media only screen and (max-width: 37.5em) {
  .header {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 85vh, 0 100%);
  }
}
.header__logo-box {
  position: absolute;
  top: 4rem;
  left: 4rem;
}
.header__logo {
  height: 3.5rem;
}
.header__text-box {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.book {
  background-image: linear-gradient(
      105deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 50%,
      transparent 50%
    ),
    url(../assets/img/book.jpg);
  background-size: 100%;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 75em) {
  .book {
    background-image: linear-gradient(
        105deg,
        rgba(255, 255, 255, 0.9) 0%,
        rgba(255, 255, 255, 0.9) 65%,
        transparent 65%
      ),
      url(../assets/img/book.jpg);
    background-size: cover;
  }
}
@media only screen and (max-width: 56.25em) {
  .book {
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.9) 0%,
        rgba(255, 255, 255, 0.9) 100%
      ),
      url(../assets/img/book.jpg);
  }
}
.book__form {
  @apply w-1/2 p-4 md:p-8 lg:p-24;
}
@media only screen and (max-width: 75em) {
  .book__form {
    width: 65%;
  }
}
@media only screen and (max-width: 56.25em) {
  .book__form {
    width: 100%;
  }
}